import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SidenavService } from '../../shared/services';
import { FsStore } from '@firestitch/store';
import { FsIFrame } from '@firestitch/iframe';
import { SidenavConfig, ItemActionType } from '@firestitch/sidenav';

@Component({
  selector: 'app-page-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit {

  public searchName: string;
  public account;
  public sideNav: SidenavConfig;

  constructor(
    public sidenavService: SidenavService,
    private fsStore: FsStore,
    private route: ActivatedRoute,
    private fsIFrame: FsIFrame,
  ) {}

  public ngOnInit() {

    this.route.data.subscribe(({ sideNav }) => {
      this.sideNav = { items: sideNav.items };

      this.sideNav.items.forEach( (item: any) => {

        if (item.path) {
          item.actions = [
            {
              type: ItemActionType.Button,
              url: 'http://' + item.path + '.components.firestitch.com',
              icon: 'open_in_new',
              class: 'new'
            }
          ]
        }
      });
    });

    this.fsStore.observe('account').subscribe((store) => {
      this.account = store.value;
    });
  }

  public clickNav($event) {
    this.fsIFrame.updateCurrentHeight();
    this.sidenavService.hide();
  }
}
