<a class="menu" [matMenuTriggerFor]="menu">
  <ng-content></ng-content>
</a>
<mat-menu #menu="matMenu">
  <div class="menu-account">
    <div fxLayout="row" class="heading" fxLayoutAlign="start center">
      <img class="avatar" [src]="account.image.small || '/assets/account-avatar.jpg'">
      <div>
        <div>{{ account.first_name }} {{ account.last_name }}</div>
        <div class="mat-caption">{{ account.email }}</div>
      </div>
    </div>
    <div class="buttons">
      <button mat-menu-item routerLink="/account">My Account</button>
      <button mat-menu-item routerLink="/admin">Admin</button>
      <a mat-menu-item [href]="backendUrl">Backend</a>
      <button mat-menu-item (click)="logout()">Sign out</button>
    </div>
  </div>
</mat-menu>