import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';


@Injectable()
export class MainSidenavResolve implements Resolve<any> {

  resolve(route: ActivatedRouteSnapshot) {
    return {
      search: true,
      items: [
        {
          label: 'Accounts',
          icon: 'person',
        },
        {
          label: 'Admin',
          icon: 'settings',
          items: [
            {
              label: 'Tasks',
              link: '/admin/tasks/types'
            },
            {
              label: 'Docs',
              link: '/admin/docs/types'
            }
          ]
        }
      ]
    }
  }

}
