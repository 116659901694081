import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SessionService } from '../../core/services';


@Injectable()
export class LoggedInGuard implements CanActivate {

  constructor(public jwtHelper: JwtHelperService,
              private router: Router,
              private sessionService: SessionService) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!this.sessionService.isExpired()) {
      return true;
    }

    this.router.navigate(['/login'], { queryParams: { redirect: state.url } });
    return false;
  }
}
