import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { FsStore } from '@firestitch/store';
import { AuthService } from '../../core/services';

@Injectable()
export class AdminGuard implements CanActivate {

  constructor(private fsStore: FsStore) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const account = this.fsStore.get('account');

    if (account && account.permissions && account.permissions.appadmin && account.permissions.appadmin >= 10) {
      return true;
    }

    return false;
  }
}
