import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { FsStore } from '@firestitch/store';
import { isBefore } from 'date-fns'


@Injectable()
export class SessionService {

  constructor(public jwtHelper: JwtHelperService,
              private fsStore: FsStore) {
  }

  public set(data) {
    this.fsStore.set('token', data.token);
    this.fsStore.set('account', data.account);
  }

  public destroy() {
    this.fsStore.remove('token');
    this.fsStore.remove('account');
  }

  public isExpired() {
    const token = this.getToken();
    return !token || isBefore(token.expiry_date, new Date());
  }

  public hasToken() {
    return !!this.getToken();
  }

  public setToken(token) {
    this.fsStore.set('token', token);
  }

  public getToken() {
    try {
      if (this.fsStore.get('token')) {
        return this.jwtHelper.decodeToken(this.fsStore.get('token'));
      }
    } catch (e) {}

    return null;
  }
}
