import { Inject, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { FsApiResponseHandler } from '@firestitch/api';
import { FsMessage, MessageMode } from '@firestitch/message';

import { SessionService } from '../services';


export class ApiHandler extends FsApiResponseHandler {
  private sessionService;

  constructor(private fsMessage: FsMessage,
              private router: Router,
              @Inject(Injector) private injector: Injector
  ) {
    super();
    this.sessionService = this.injector.get(SessionService);
  }

  public success(event, config) {
    if (event.body.token) {
      this.sessionService.setToken(event.body.token);
    }

    event.body = event.body.data;
    if (config.key) {
      event.body = event.body[config.key];
    }
  }

  public error(event, config) {

    if (event.status === 401 || event.status === 403) {

      this.fsMessage.error('Please login to access this page', { mode: MessageMode.Toast });
      this.sessionService.destroy();
      this.router.navigateByUrl('/login');

    } else {

      if (config.handleError === false) {
        return;
      }

      if (event.error && event.error.messages) {
        const messages = [];
        for (const message of event.error.messages) {
          messages.push(message.message);
        }

        this.fsMessage.error(messages.join('<br/>'));
      } else {
        this.fsMessage.error('There was an unknown error');
      }
    }
  }
}
