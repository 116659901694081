<div fsScrollContent class="app-scroll-content">
  <div class="container container-side-nav">
    <mat-sidenav-container>
      <mat-sidenav
        [mode]="sidenavService.mode"
        [opened]="sidenavService.opened"
        [fixedTopGap]="sidenavService.topGap"
        [fixedInViewport]="true">

        <div class="logo">
          <a routerLink="/" class="image"></a>
          <a routerLink="/" class="logo-name">Firestitch</a>
        </div>

        <div class="side-nav" *ngIf="sideNav.items && sideNav.items.length">

          <mat-form-field class="search" floatLabel="never">
            <div matPrefix><mat-icon>search</mat-icon></div>
            <input matInput [(ngModel)]="searchName" placeholder="Search" autofocus>
          </mat-form-field>

          <fs-sidenav
            [config]="sideNav"
            [search]="searchName"
            (click)="clickNav($event)"></fs-sidenav>
        </div>

      </mat-sidenav>
      <mat-sidenav-content>
        <navbar></navbar>
        <router-outlet></router-outlet>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</div>