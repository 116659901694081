/**
 * App can be named as RootModule, it has includes/imports of all project modules as a dependency.
 * Usually it doesn't contain any providers and providers are placed in Core
 */
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { CoreModule } from './core/core.module';
import { SharedModule } from './shared';
import { MainModule } from './main';
import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { AdminGuard, LoggedInGuard, LoggedOutGuard } from './shared/guards';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    MainModule,
    HttpClientModule
  ],
  providers: [
    LoggedInGuard,
    LoggedOutGuard,
    AdminGuard
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
