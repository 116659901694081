/**
 * Main module can be named as Pages i.e.
 * So, Main module contains site layout base elements menus/sidebars/header/footer and other static components
 * Also it can contain static pages, but if you will need lot's of static pages would be nice to create special PagesModule
 */
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';

import { SharedModule } from '../shared';
import {
  ComponentsSidenavResolve
} from './resolves';
import { WideComponent, CardComponent, SideNavComponent } from './outlets';
import { FsPasswordModule } from '@firestitch/password';
import { FsSidenavModule } from '@firestitch/sidenav';

import { SidenavService } from './shared/services';

import { throwIfAlreadyLoaded } from '../core/module-import-guard';


@NgModule({
  imports: [
    SharedModule,
    RouterModule,
    FsPasswordModule,
    FsSidenavModule
  ],
  declarations: [
    WideComponent,
    SideNavComponent,
    CardComponent,
  ],
  entryComponents: [],
  providers: [
    SidenavService,
    ComponentsSidenavResolve
  ],
  exports: []
})
export class MainModule {
  constructor(@Optional() @SkipSelf() parentModule: MainModule) {
    throwIfAlreadyLoaded(parentModule, 'MainModule');
  }
}
