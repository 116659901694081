import { Injectable } from '@angular/core';
import { FsApi } from '@firestitch/api';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class AccountService {

  public STATE_ACTIVE = 'active';
  public STATE_DELETED = 'deleted';

  public states = [
    { name: 'Active', value: this.STATE_ACTIVE },
    { name: 'Deleted', value: this.STATE_DELETED }
  ];

  constructor(private fsApi: FsApi) {
  }

  public get(account_id): Observable<any> {
    return this.fsApi.get(`accounts/${account_id}`, {}, { key: 'account' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this.fsApi.request(`GET`, `accounts`, data, Object.assign({ key: 'accounts' }, config));
  }

  public put(account, config = {}): Observable<any> {
    return this.fsApi.put(`accounts/${account.id}`, account, Object.assign({ key: 'account' }, config));
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

  public post(account): Observable<any> {
    return this.fsApi.post(`accounts`, account, { key: 'account' });
  }

  public delete(account): Observable<any> {
    return this.fsApi.delete(`accounts/${account.id}`, account, { key: 'account' });
  }

  public image(account, file): Observable<any> {
    return this.fsApi.post(`accounts/${account.id}/image`, { file: file }, { key: 'account' });
  }
}
