import { Injectable } from '@angular/core';
import { FsApi } from '@firestitch/api';
import { Observable } from 'rxjs/Observable';
import { SessionService } from './session.service';
import { Router } from '@angular/router';


@Injectable()
export class AuthService {

  constructor(private fsApi: FsApi,
              private router: Router,
              private sessionService: SessionService) {
  }

  public login(email, password): Observable<any> {
    return this.fsApi.post('auth/login', { email: email, password: password });
  }
  
  public destroy() {
    this.sessionService.destroy();
  }

  public logout() {
    this.destroy();
    this.router.navigateByUrl('/login');
  }
}
