import { Component, OnInit, Input } from '@angular/core';

import { AuthService } from '../../../core/services';


@Component({
  selector: 'account-menu',
  templateUrl: './account-menu.component.html',
  styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit {
  @Input() account;
  public backendUrl;
  
  constructor(private authService: AuthService) {
    this.backendUrl = '//' + (<any>window).location.hostname + '/backend';
  }

  public ngOnInit() {
   
  }

  public logout() {
    this.authService.logout();
  }

  public onClick($event) {
    $event.stopPropagation();
  }  
}
