import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { FsApi } from '@firestitch/api';


@Injectable()
export class ObjectService {

  public CLASS_DOC = 'doc';
  public CLASS_TASK = 'task';
  public CLASS_FILE = 'file';

  public classes = [
    { name: 'Doc', value: this.CLASS_DOC, icon: 'description' },
    { name: 'Task', value: this.CLASS_TASK, icon: 'assignment' },
    { name: 'File', value: this.CLASS_FILE, icon: 'insert_drive_file' }
  ];

  constructor(private _fsApi: FsApi) { }

  public get(object_id, query = {}): Observable<any> {
    return this._fsApi.get(`objects/${object_id}`, query, { key: 'object' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'objects', data, Object.assign({ key: 'objects' }, config));
  }

}
