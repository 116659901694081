<mat-toolbar color="primary">

  <div fxFlex fxLayout="row" fxLayoutAlign="start center">
    <a fsNavBack><mat-icon>keyboard_arrow_left</mat-icon></a>
    <button
      mat-icon-button
      class="menu"
      (click)="sideNavClick()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <a routerLink="/" class="logo"></a>

  <div fxFlex fxLayout="row" fxLayoutAlign="end center">

  </div>
</mat-toolbar>
