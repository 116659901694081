<div fxLayout fxLayout="row" fxLayoutAlign="start center" class="navbar">
  <a class="back" fsNavBack><mat-icon>arrow_back</mat-icon></a>
  <div class="left-actions" fsNavActions placement="left"></div>
  <span fxFlex>
    <div fxLayout fxLayout="row" fxLayoutAlign="left center">
      <div fsNavComponent="avatar" type="image"></div>
      <div>
        <div fsNavSupertitle></div>
        <div fsNavTitle></div>
        <div fsNavSubtitle></div>
      </div>
    </div>
  </span>
  <div class="actions" fsNavActions placement="right"></div>
</div>