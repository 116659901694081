/**
 * Core module it's a main module in project after AppModule.
 * This module contain providers initializations and usually all modules .forRoot() expressions
 * Core module can be imported only once and by this reason providers which has been declared here
 * will be a singleton across all project. (if they was not re-declared in some component)
 */
import { NgModule, Optional, SkipSelf, Injector } from '@angular/core';
import { Router } from '@angular/router';

import {
  FS_API_REQUEST_INTERCEPTOR,
  FS_API_RESPONSE_HANDLER,
  FsApi,
  FsApiModule
} from '@firestitch/api';

import { FsStore, FsStoreModule } from '@firestitch/store';
import { FsBodyModule } from '@firestitch/body';
import { FsIFrameModule } from '@firestitch/iframe';

import {
  FsScrollComponent,
  FsScrollContentComponent,
  FsScrollModule,
  FsScrollService
} from '@firestitch/scroll';

import { FsMessage, FsMessageModule } from '@firestitch/message';

import { ToastrModule } from 'ngx-toastr';
import { JWT_OPTIONS, JwtModule } from '@auth0/angular-jwt';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {  AccountService,
          SessionService,
          AuthService,
          OrderService,
          ObjectService,
          TypeService,
          TagService,
          CategoryService,
          StatusService,
          WorkflowService
        } from './services';

import { ApiHandler } from './handlers';
import { ApiInterceptorFactory } from './interceptors';
import { FsNavModule } from '@firestitch/nav';

export function jwtOptionsFactory(fsStore) {
  return {
    tokenGetter: () => {
      return fsStore.get('token');
    },
    whitelistedDomains: []
  }
}


@NgModule({
  imports: [
    FsApiModule.forRoot(),
    FsStoreModule.forRoot(),
    LoadingBarHttpClientModule,
    FsMessageModule.forRoot(),
    ToastrModule.forRoot({ preventDuplicates: true }),
    FsScrollModule.forRoot(),
    FsBodyModule.forRoot(),
    FsNavModule.forRoot(),
    FsIFrameModule.forRoot(),
    JwtModule.forRoot({
      jwtOptionsProvider: {
        provide: JWT_OPTIONS,
        useFactory: jwtOptionsFactory,
        deps: [FsStore]
      }
    }),
  ],
  providers: [
    SessionService,

    AuthService,
    AccountService,
    OrderService,
    TypeService,
    TagService,
    CategoryService,
    StatusService,
    ObjectService,
    WorkflowService,
    {
      provide: FS_API_REQUEST_INTERCEPTOR,
      useFactory: ApiInterceptorFactory,
      deps: [ FsStore ]
    },
    {
      provide: FS_API_RESPONSE_HANDLER,
      useClass: ApiHandler,
      deps: [ FsMessage, Router, Injector ],
    }
  ],
  exports: [
    LoadingBarHttpClientModule,
    FsScrollComponent,
    FsScrollContentComponent
  ]
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
  }
}
