/**
 * Shared module must contain modules/components/directives/etc. which must have a possibility to use everywhere in project
 * As example it can be RouterModule, Material Modules, Some components like "Follow" button
 * !!!Shared module CAN NOT CONTAIN SERVICES!!!
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';

import { FsCommonModule } from '@firestitch/common';
import { FsBadgeModule } from '@firestitch/badge';
import { FsListModule } from '@firestitch/list';
import { FsPromptModule } from '@firestitch/prompt';
import { FsFormModule } from '@firestitch/form';
import { FsDateModule } from '@firestitch/date';
import { FsNavModule } from '@firestitch/nav';
import { FsSkeletonModule } from '@firestitch/skeleton';
import { FsFilterModule } from '@firestitch/filter';
import { FsBannerComponent,
         FsBannerModule } from '@firestitch/banner';

import { AppMaterialModule } from '../material';
import {  HeaderComponent,
          AccountMenuComponent,
          NavbarComponent } from './components';


@NgModule({
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    FsBadgeModule,
    FsCommonModule,
    AppMaterialModule,
    FsBannerModule,
    FsDateModule,
    FsNavModule,
    FsSkeletonModule,
    FsFilterModule,
    FsListModule.forRoot(),
    FsPromptModule.forRoot(),
    FsFormModule.forRoot()
  ],
  declarations: [
    HeaderComponent,
    AccountMenuComponent,
    NavbarComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    FsCommonModule,
    AppMaterialModule,
    HeaderComponent,
    FsBannerComponent,
    FsListModule,
    FsFormModule,
    FsDateModule,
    FsBadgeModule,
    FsNavModule,
    AccountMenuComponent,
    NavbarComponent,
    FsSkeletonModule,
    FsFilterModule
  ],
})
export class SharedModule {
}
