import { Injectable } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { FsApi } from '@firestitch/api';

import { Workflow } from '../../shared/interfaces';


@Injectable()
export class WorkflowService {

  constructor(private _fsApi: FsApi) { }

  public create(data: Workflow = { id: null }): Workflow {
    data.class = data.class || 'task';
    return data;
  }

  public get(id, query = {}): Observable<any> {
    return this._fsApi.get(`workflows/${id}`, query, { key: 'workflow' });
  }

  public gets(data = {}, config = {}): Observable<any> {
    return this._fsApi.request('GET', 'workflows', data, Object.assign({ key: 'workflows' }, config));
  }

  public put(data, config = {}): Observable<any> {
    return this._fsApi.put(`workflows/${data.id}`, data, Object.assign({ key: 'workflow' }, config));
  }

  public post(data): Observable<any> {
    return this._fsApi.post('workflows', data, { key: 'workflow' });
  }

  public delete(data): Observable<any> {
    return this._fsApi.delete(`workflows/${data.id}`, data, { key: 'workflow' });
  }

  public save(data): Observable<any> {
    if (data.id) {
      return this.put(data);
    }
    return this.post(data);
  }

}
