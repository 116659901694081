import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { SideNavComponent } from './main/outlets';
import {
  ComponentsSidenavResolve
} from './main/resolves';


export const routes: Routes = [
  {
    path: '',
    component: SideNavComponent,
    children: [{
      path: '',
      loadChildren: () => import('app/components/components.module').then(m => m.ComponentsModule),
    }],
    data: { bodyClass: 'body-side-nav' },
    resolve: {
      sideNav: ComponentsSidenavResolve
    }
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    {
    useHash: (+document.location.port === 8080 || document.location.protocol === 'file:'),
    preloadingStrategy: PreloadAllModules,
    relativeLinkResolution: 'legacy'
}
  )],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
