
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { ItemActionType } from '@firestitch/sidenav';
import { COMPONENT_CATEGORIES } from 'app/shared/consts/component-categories';


@Injectable()
export class ComponentsSidenavResolve implements Resolve<any> {

  resolve(route: ActivatedRouteSnapshot) {

    const items = [];

    COMPONENT_CATEGORIES.forEach(category => {

      items.push({ label: category.name, class: 'heading', image: `/assets/categories/white/${category.image}` });

      category.components.forEach(component => {
        items.push(component);
      });
    });

    return {
      search: true,
      items: items
    }
  }
}