export const COMPONENT_CATEGORIES = [
  {
    name: 'Inputs',
    image: 'inputs.svg',
    components: [
      { path: '/input', label: 'Input' },
      { path: '/selectbutton', label: 'Select Button' },
      { path: '/select', label: 'Select' },
      { path: '/phone', label: 'Phone' },
      { path: '/autocomplete', label: 'Autocomplete' },
      { path: '/autocompletechips', label: 'Autocomplete Chips' },
      { path: '/checkboxgroup', label: 'Checkbox Group' },
      { path: '/radiogroup', label: 'Radiobutton Group' },
      { path: '/toggle', label: 'Toggle' },
      { path: '/buttontoggle', label: 'Button Toggle' },
      { path: '/duration', label: 'Duration' },
      { path: '/rating', label: 'Rating' },
      { path: '/mask', label: 'Mask' },
      { path: '/codeinput', label: 'Code Input' },
    ]
  },
  {
    name: 'Pickers',
    image: 'pickers.svg',
    components: [
      { path: '/address', label: 'Address Picker' },
      { path: '/payment', label: 'Payment Picker' },
      { path: '/datepicker', label: 'Date/Time Picker' },
      { path: '/password', label: 'Password Picker' },
      { path: '/iconpicker', label: 'Icon Picker' },
      { path: '/colorpicker', label: 'Color Picker' },
      { path: '/scrollpicker', label: 'Scroll Picker' }
    ]
  },
  {
    name: 'Forms',
    image: 'forms.svg',
    components: [
      { path: '/form', label: 'Form Validation' }
    ]
  },
  {
    name: 'Files',
    image: 'files.svg',
    components: [
      { path: '/file', label: 'File' },
      { path: '/import', label: 'Import' },
      { path: '/transfer', label: 'Transfer' },
      { path: '/upload', label: 'Upload' }
    ]
  },
  {
    name: 'Content',
    image: 'content.svg',
    components: [
      { path: '/video', label: 'Video' },
      { path: '/message', label: 'Message' },
      { path: '/date', label: 'Date (Date, Ago, Range)' },
      { path: '/flipbook', label: 'Flipbook' },
      { path: '/gallery', label: 'Gallery' },
      { path: '/skeleton', label: 'Skeleton Screen' },
      { path: '/map', label: 'Map' },
      { path: '/popover', label: 'Popover' },
      { path: '/style', label: 'Style' },
      { path: '/contentwidget', label: 'Content Widget' }
    ]
  },
  {
    name: 'Layout & Navigation',
    image: 'layouts.svg',
    components: [
      { path: '/nav', label: 'Top Navigation' },
      { path: '/sidenav', label: 'Side Navigation' },
      { path: '/drawer', label: 'Drawer' },
      { path: '/dialog', label: 'Dialog' },
      { path: '/prompt', label: 'Prompt' },
      { path: '/iframe', label: 'IFrame' },
      { path: '/banner', label: 'Banner' },
      { path: '/responsive', label: 'Responsive' },
      { path: '/scroll', label: 'Infinity Scroll' },
      { path: '/menu', label: 'Menu' },
      { path: '/tab', label: 'Tabs' }
    ]
  },
  {
    name: 'List & Filters',
    image: 'lists.svg',
    components: [
      { path: '/filter', label: 'Filter' },
      { path: '/list', label: 'List' }
    ]
  },
  {
    name: 'Editors',
    image: 'editors.svg',
    components: [
      { path: '/htmleditor', label: 'Html Editor' },
      { path: '/fieldeditor', label: 'Field Editor' },
      { path: '/imageeditor', label: 'Image Editor' },
      { path: '/texteditor', label: 'Text Editor' }
    ]
  },
  {
    name: 'Other',
    image: 'other.svg',
    components: [
      { path: '/api', label: 'API' },
      { path: '/common', label: 'Common' },
      { path: '/badge', label: 'Badge' },
      { path: '/link', label: 'Link' },
      { path: '/meta', label: 'Meta' },
      { path: '/connection', label: 'Connection' },
      { path: '/selection', label: 'Selection' },
      { path: '/clear', label: 'Clear' },
      { path: '/progress', label: 'Progress' },
      { path: '/label', label: 'Label' },
      { path: '/zoompan', label: 'Zoom & Pan' },
      { path: '/diagram', label: 'Diagram' },
      { path: '/tree', label: 'Tree' },
      { path: '/legend', label: 'Legend' },
      { path: '/chip', label: 'Chip' },
      { path: '/attribute', label: 'Attributes' },
      { path: '/accountstatus', label: 'Account Status' },
      { path: '/signinsecurity', label: 'Sign In & Security' },
      { path: '/modelchange', label: 'Model Change' },
      { path: '/wizard', label: 'Wizard' },
      { path: '/share', label: 'Share' },
      { path: '/truncate', label: 'Truncate' },
      { path: '/scrollbar', label: 'Scrollbar' },
      { path: '/tile', label: 'Tile' },
      { path: '/acl', label: 'ACL' },
      { path: '/signature', label: 'Signature' },
      { path: '/country', label: 'Country' },
      { path: '/process', label: 'Process' },
      { path: '/device', label: 'Device' },
      { path: '/trusteddevice', label: 'Trusted Device' },
      { path: '/2fa', label: '2FA' },
      { path: '/availability', label: 'Availability' },
      { path: '/blockeditor', label: 'Block Editor' },
      { path: '/pdf', label: 'PDF' },
      { path: '/clipboard', label: 'Clipboard' },
    ]
  }
]
